import React from "react";

import CotNav from "./COTNavbar/Navbar";
import { MDBContainer } from "mdbreact";
import Aux from "../Auxilliary";

const cotLayout = props => {
  return (
    <Aux>
      <CotNav />
      <div>{props.children}</div>
    </Aux>
  );
};

export default cotLayout;
