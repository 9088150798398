import React, { Component } from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
} from "mdbreact";

class CotTable extends Component {
  constructor(props) {
    super(props);
    console.log("Loading CotTable");
    //console.log(this.props.future);
    //const future = this.props.future;

    this.state = {
      future: this.props.future,
      isLoading: true,
      isLoaded: false,
      urls: [
        `${process.env.REACT_APP_API_ENDPOINT}/future/list/`,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/osz/`,
      ],
      cot2: [],
      cot1: [],
    };
  }

  async componentDidMount() {
    let response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/check/cot2/30`
    );
    let responseCOT1 = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/check/cot1/30`
    );
    if (!response.ok) {
      return;
    }

    let dataCot2 = await response.json();
    let dataCot1 = await responseCOT1.json();
    this.setState({ loading: false, cot2: dataCot2, cot1: dataCot1 });
    //console.log("loading NAVBAR");
    //console.log(this.props.match.path + "/ZW");
  }

  renderTableHeader() {
    /*  return this.state.cotData.map((data, index) => {
      console.log("data");
      console.log(data);
       const { Future_id, Symbol, Type, Period, Value, CreatedOn } = data //destructuring
       console.log(Symbol);
       return (
         <tr>
         <th>Future_id</th>
         <th>Symbol</th>
         <th>Type</th>
         <th>Period</th>
         <th>Value</th>
         <th>CreatedOn</th>
         </tr>
       )
    })*/
    return (
      <tr>
        <th>Symbol</th>
        <th>Type</th>
        <th>Period</th>
        <th>Value</th>
      </tr>
    );
  }

  renderCot2Data() {
    return this.state.cot2.map((data, index) => {
      //console.log("data");
      //console.log(data);
      const { Future_id, Symbol, Type, Period, Value, CreatedOn } = data; //destructuring
      //  console.log(Symbol);
      if (Value <= 25) {
        return (
          <MDBTableBody color="green">
            <tr key={Future_id}>
              <td>{Symbol}</td>
              <td>{Type}</td>
              <td>{Period}</td>
              <td>{Value}</td>
            </tr>
          </MDBTableBody>
        );
      } else if (Value >= 75) {
        return (
          <MDBTableBody color="red">
            <tr key={Future_id}>
              <td>{Symbol}</td>
              <td>{Type}</td>
              <td>{Period}</td>
              <td>{Value}</td>
            </tr>
          </MDBTableBody>
        );
      } else {
        return (
          <MDBTableBody>
            <tr key={Future_id}>
              <td>{Symbol}</td>
              <td>{Type}</td>
              <td>{Period}</td>
              <td>{Value}</td>
            </tr>
          </MDBTableBody>
        );
      }
    });
  }

  renderCot1Data() {
    return this.state.cot1.map((data, index) => {
      //console.log("data");
      //console.log(data);
      const { Future_id, Symbol, Type, Period, Value, CreatedOn } = data; //destructuring
      //  console.log(Symbol);
      if (Value >= 90) {
        return (
          <MDBTableBody color="green">
            <tr key={Future_id}>
              <td>{Symbol}</td>
              <td>{Type}</td>
              <td>{Period}</td>
              <td>{Value}</td>
            </tr>
          </MDBTableBody>
        );
      } else if (Value <= 10) {
        return (
          <MDBTableBody color="red">
            <tr key={Future_id}>
              <td>{Symbol}</td>
              <td>{Type}</td>
              <td>{Period}</td>
              <td>{Value}</td>
            </tr>
          </MDBTableBody>
        );
      } else {
        return (
          <MDBTableBody>
            <tr key={Future_id}>
              <td>{Symbol}</td>
              <td>{Type}</td>
              <td>{Period}</td>
              <td>{Value}</td>
            </tr>
          </MDBTableBody>
        );
      }
    });
  }

  render() {
    if (!this.state.loading) {
      //console.log("loading Header");

      return (
        <div>
          <MDBRow>
            <MDBCol md="6">
              <MDBCard>
                <MDBCardBody>
                  <MDBTable hover>
                    <MDBTableHead color="teal" textWhite>
                      {this.renderTableHeader()}
                    </MDBTableHead>
                    {this.renderCot2Data()}
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol md="6">
              <MDBCard>
                <MDBCardBody>
                  <MDBTable hover>
                    <MDBTableHead color="teal" textWhite>
                      {this.renderTableHeader()}
                    </MDBTableHead>
                    {this.renderCot1Data()}
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
      );
    }
    return <h2 className="FutureTable-title">Waiting for API...</h2>;
  }
}

export default CotTable;
