import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";
//import BuyButton from './BuyButton';

class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, product: {} }
  }

  async componentDidMount() {
    let response = await fetch(`http://127.0.0.1:1337/blogs/${this.props.match.params.id}`)
    let data = await response.json()
    this.setState({
      loading: false,
      product: data
    })
    console.log(this.state.product);
  }
//
  render() {
    if (!this.state.loading) {
      return (
        <MDBRow>
        <MDBCol md="8">
        <MDBCard>
        <MDBCardBody>

            <h2 className="Product-title">{this.state.product.title}</h2>
            <MDBCard reverse>
            <MDBView hover cascade waves>
            <img
                src={`http://127.0.0.1:1337/${this.state.product.image[0].url}`}
                alt=""
                className="img-fluid"
            />
            <MDBMask overlay="white-slight" className="waves-light" />
            </MDBView>
            </MDBCard>
            <MDBContainer className="mt-5">
                <p>
                {this.state.product.content} </p>
                <p>
                <img src={`http://127.0.0.1:1337/${this.state.product.image[0].url}`} />
                </p>
            </MDBContainer>

        </MDBCardBody>
        </MDBCard>
        </MDBCol>
        </MDBRow>
      );
    }

    return (<h2>Waiting for API...</h2>);
  }
}

export default Blog;
