import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBContainer,
  MDBCard,
  MDBCardBody
} from "mdbreact";

import COTLayout from "../../hoc/COTLayout/COTLayout";
import CotModule from "../../containers/CotModule/CotModule";

class COTTools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      products: []
    };
  }

  componentDidMount() {
    console.log("getting Data 2");
    console.log(this.props.match.params.id);
  }
  //{this.state.products.length}
  //<CotModule future={product.Symbol} />
  /*
  <Route
      exact
      path={this.props.match.url}
      render={props => <CotModule future={this.props.match.params.id} />}
    />
    */
  render() {
    return (
      <MDBContainer style={{ maxWidth: "95%" }}>
        <COTLayout>
          <div className="future-graph" key={this.props.match.params.id}>
            <CotModule future={this.props.match.params.id} />
          </div>
        </COTLayout>
      </MDBContainer>
    );
  }
}

export default COTTools;
