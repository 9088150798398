import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Link } from 'react-router-dom';
import {
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBTable
} from "mdbreact";

import COTLayout from "../../hoc/COTLayout/COTLayout";
import CotTable from "../../containers/CotModule/CotTable";

class TSKTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      products: [],
    }
  }

  componentDidMount() {
    console.log("first mount Cot-tool");
    console.log(this.props.match.url);
  }
  //{this.state.products.length}
  //<CotModule future={product.Symbol} />
  render() {
      return (
        <MDBContainer style={{maxWidth:"95%"}}>
          <COTLayout>

          </COTLayout>
        </MDBContainer>

      );
    }
}


export default TSKTool;
