import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CotModule from "../../containers/CotModule/CotModule";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";
//import BuyButton from '../../components/BuyButton';
class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      products: []
    }
  }

  async componentDidMount() {
    let response = await fetch("http://127.0.0.1:1337/blogs/");
    if (!response.ok) {
      return
    }

    let products = await response.json()
    this.setState({ loading: false, products: products })
    //console.log(this.state.products.image[0]);

  }

  render() {
    if (!this.state.loading) {
      return (
        <div className="ProductList">
          <h2 className="ProductList-title">Blog Einträge ({this.state.products.length})</h2>
          <div className="ProductList-container">
            {this.state.products.map((product, index) => {
              return (
                  <MDBRow>
                  <MDBCol md="8">
                    <MDBCard>
                      <MDBCardHeader color="blue-grey darken-2">
                        <h4>{product.title}</h4>
                      </MDBCardHeader>
                      <MDBCardBody>
                  <div className="ProductList-product" key={product.id}>

                    <Link to={`/wiki/wiki/${product.id}`}>
                      <h3>{product.categories[0].name}</h3>
                      <img src={`http://127.0.0.1:1337${product.image[0].url}`} class="rounded-circle mr-3" height="150px" width="150px" alt={product.title} />
                    </Link>
                  </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              </MDBRow>


              );
            })}
          </div>
        </div>
      );
    }

    return (<h2 className="ProductList-title">Waiting for API...</h2>);
  }
}

export default ProductList;
