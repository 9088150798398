import React from "react";
import Aux from "../Auxilliary";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";

const layout = props => (
  <Aux>
    <Navbar />
    <main>{props.children}</main>
    <Footer />
  </Aux>
);

export default layout;
