import React from "react";
import ReactDOM from "react-dom";
import { ResponsiveLine } from "@nivo/line";
import * as time from "d3-time";
import { timeFormat } from "d3-time-format";

const COT2_OSZ = ({ userData, name }) => {
  return (
    <div className="App" style={{ height: "20vh", width: "100%" }}>
      {/* <h1>{name}</h1> */}
      <ResponsiveLine
        data={userData}
        margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
        xScale={{ type: "time", format: "%Y-%m-%d", precision: "day" }}
        xFormat="time:%Y-%m-%d"
        yScale={{ type: "linear", stacked: true, min: "0", max: "100" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: "%Y",
          tickValues: "every 1 year",
          //tickRotation: -90,
          legendOffset: -12
        }}
        markers={[
          {
            axis: "y",
            value: 90,
            lineStyle: { stroke: "#42f54b", strokeWidth: 2 },
            legendOrientation: "vertical"
          },
          {
            axis: "y",
            value: 10,
            lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
            legendOrientation: "vertical"
          }
        ]}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: "middle"
        }}
        enableGridX={false}
        colors={{ scheme: "category10" }}
        pointSize={8}
        lineWidth={3}
        pointColor={{ theme: "background" }}
        pointBorderWidth={1}
        pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        crosshairType="cross"
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};

export default COT2_OSZ;
