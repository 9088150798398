import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import Layout from "./hoc/Layout/Layout";
import LandingPage from "./pages/landingPage/LandingPage";
import Blog from "./pages/blog/Blog";
import Wiki from "./pages/wiki/Wiki";
import COT from "./pages/cot-tool/Cot-Tool";
import COT1 from "./pages/cot-tool/Cot-Tool1";
import COTShortTerm from "./pages/cot-tool/Cot-Tool-Short-Term";
import TSK from "./pages/tsk/tsk";
import TSKID from "./pages/tsk/tskID";

import Blog2 from "./containers/Blog/Blog";
import Blog3 from "./pages/wiki/Wiki";

class App extends Component {
  render() {
    return (
      <Layout>
        <Route exact path="/" component={COT} />
        <Route exact path="/blog" component={Blog} />
        <Switch>
          <Route path="/wiki/wiki/:id" component={Blog2} />
          <Route path="/wiki" component={Blog3} />
        </Switch>
        <Switch>
          <Route path="/cot-tool/:id" component={COT1} />
          <Route path="/cot-tool" component={COT} />
        </Switch>
        <Switch>
          <Route path="/cot-tool-short-term/:id" component={COTShortTerm} />
          <Route path="/cot-tool-short-term" component={COT} />
        </Switch>
        <Switch>
          <Route path="/tsk/:id" component={TSKID} />
          <Route path="/tsk" component={TSK} />
        </Switch>
        {/* <Route exact path="/cot-tool/ZW" component={CotModule} /> */}
        {/* <Route
          exact
          path="/cot-tool/ZC"
          render={props => <CotModule {...props} future={"zc"} />}
        />
        <Route
          exact
          path="/cot-tool/ZW"
          render={props => <CotModule {...props} future={"zw"} />}
        /> */}
      </Layout>
    );
  }
}

export default App;
