import React from "react";
import { MDBSpinner } from 'mdbreact';

const SpinnerPage = () => {
  return (
    <>
      <MDBSpinner yellow/>
    </>
  );
}

export default SpinnerPage;