import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNav,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBFormInline,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  Navbar,
  MDBView,
  MDBMask,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";
import "./Navbar.css";

class NavbarPage extends Component {
  state = {
    isOpen: false,
    activeItem: "4",
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggle = (tab) => () => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    return (
      <MDBNavbar
        color="blue-grey darken-2"
        dark
        expand="md"
        fixed="top"
        className="shadow-box-example z-depth-2"
        scrolling
      >
        <MDBContainer>
          <MDBNavbarBrand href="./" className="Brand float-right">
            <strong className="white-text">traDeLux</strong>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav className="d-flex justify-content-center">
              <MDBNavItem
                className={this.state.activeItem === "1" ? "active" : ""}
                onClick={this.toggle("1")}
              >
                <MDBNavLink disabled className="mx-2 font-weight-bolder" to="/">
                  <MDBView hover zoom>
                    Home
                  </MDBView>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem
                className={this.state.activeItem === "2" ? "active" : ""}
                onClick={this.toggle("2")}
              >
                <MDBNavLink
                  disabled
                  className="mx-2 font-weight-bolder"
                  to="/blog"
                >
                  Blog
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem
                className={this.state.activeItem === "3" ? "active" : ""}
                onClick={this.toggle("3")}
              >
                <MDBNavLink
                  disabled
                  className="mx-2 font-weight-bolder"
                  to="/wiki"
                >
                  Wiki
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem
                className={this.state.activeItem === "4" ? "active" : ""}
                onClick={this.toggle("4")}
              >
                <MDBNavLink className="mx-2 font-weight-bolder" to="/cot-tool">
                  COT-Tool
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem
                className={this.state.activeItem === "5" ? "active" : ""}
                onClick={this.toggle("5")}
              >
                <MDBNavLink
                  className="mx-2 font-weight-bolder"
                  to="/cot-tool-short-term"
                >
                  COT-Tool Short-Term
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem
                className={this.state.activeItem === "6" ? "active" : ""}
                onClick={this.toggle("6")}
              >
                <MDBNavLink
                  disabled
                  className="mx-2 font-weight-bolder"
                  to="#!"
                >
                  Futures
                </MDBNavLink>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    );
  }
}

export default NavbarPage;
