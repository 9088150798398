import React, { Component } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact";

import CotABS from "../../components/nivo/cotABS";
import Cot1OSZ from "../../components/nivo/cot1OSZ";
import Cot1OSZ_Maxim from "../../components/nivo/cot1OSZ_Advanced";
import Cot2OszAdvanced from "../../components/nivo/cot2OSZ_Advanced";
import Cot2OSZ from "../../components/nivo/cot2OSZ";
import COTLayout from "../../hoc/COTLayout/COTLayout";
import Spinner from "../../components/spinners/loadingSpinner";

class CotModule extends Component {
  constructor(props) {
    super(props);
    console.log("Loading COTModule");
    console.log(this.props.future);
    const future = this.props.future;
    //this.props.match.params.id,
    this.state = {
      future: this.props.future,
      isLoading: true,
      isLoaded: false,
      urls: [
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/abs/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/osz/26/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/abs/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/osz/208/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/abs/upper-lower/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/abs/upper-lower/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/oi/abs/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/oi/osz/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/spec/ncl/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/spec/ncs/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/spec/ncl/upper-lower/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/spec/ncs/upper-lower/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/oi/cloi/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/oi/csoi/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/oi/abs/upper-lower/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/oi/csoi/upper-lower/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/osz/13/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/osz/52/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/osz/208/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/abs/disagg/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/abs/disagg/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/abs/disagg/upper-lower/` +
          future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/abs/disagg/upper-lower/` +
          future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/osz/26/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/osz/52/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/osz/104/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot2/osz/13/` + future,
        `${process.env.REACT_APP_API_ENDPOINT}/cot1/osz/104/` + future,
      ],
      cotData: [],
    };

    //console.log(this.props.match.params.id);
  }

  componentDidMount() {
    //console.log(this.props.match.params.id);
    console.log(this.state.urls);
    const test = [];
    this.setState({ isLoading: true, isLoaded: false });
    const promises = this.state.urls.map((url) => fetch(url));

    Promise.all(promises).then((results) => {
      Promise.all(results.map((result) => result.json())).then((results) => {
        results.map((result) => {
          const resObj = { id: this.state.future, data: result };
          test.push(resObj);
          //console.log(test);
        });
        this.setState({
          isLoading: false,
          isLoaded: true,
          cotData: test,
        });
        // console.log("MAX MIN SPEC");
        // console.log(this.state.cotData[21].data[0].upper);
      });
    });
  }

  render() {
    const { isLoading, isLoaded } = this.state;
    const futureName = this.state.future; //.toUpperCase();

    if (isLoading) return <Spinner />;
    if (!isLoaded) return <h1>Error when fetching data!</h1>;
    //  this.state.cotData[5].data[0].upper
    const lastItemCOTNetto = this.state.cotData[0].data[207].y;

    const OSZ_5_13_26_52_156_data = [
      this.state.cotData[16],
      this.state.cotData[1],
      this.state.cotData[17],
      this.state.cotData[27],
      this.state.cotData[18],
    ];

    const OSZ_COT2_5_13_26_52_104_208_data = [
      this.state.cotData[26],
      this.state.cotData[23],
      this.state.cotData[24],
      this.state.cotData[25],
      this.state.cotData[3],
    ];

    // const OSZ_52_156_data = [this.state.cotData[17], this.state.cotData[18]];
    OSZ_5_13_26_52_156_data[0].id = "OSZ13";
    OSZ_5_13_26_52_156_data[1].id = "OSZ26";
    OSZ_5_13_26_52_156_data[2].id = "OSZ52";
    OSZ_5_13_26_52_156_data[3].id = "OSZ104";
    OSZ_5_13_26_52_156_data[4].id = "OSZ208";

    OSZ_COT2_5_13_26_52_104_208_data[0].id = "OSZ13";
    OSZ_COT2_5_13_26_52_104_208_data[1].id = "OSZ26";
    OSZ_COT2_5_13_26_52_104_208_data[2].id = "OSZ52";
    OSZ_COT2_5_13_26_52_104_208_data[3].id = "OSZ104";
    OSZ_COT2_5_13_26_52_104_208_data[4].id = "OSZ208";

    // console.log(OSZ_13_26_data);
    // console.log(OSZ_52_156_data);

    return (
      <div>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>Commercials Short 4yrs (COT2)</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <CotABS
                  userData={[this.state.cotData[2]]}
                  name={this.props.future + "-ABS-Cot2"}
                  upper={this.state.cotData[4].data[0].upper}
                  lower={this.state.cotData[4].data[0].lower}
                  upperColor={"#b0413e"}
                  lowerColor={"#42f54b"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>Commercials Netto 4 yrs (COT1)</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <CotABS
                  userData={[this.state.cotData[0]]}
                  name={this.props.future + "-ABS-Cot1"}
                  upper={this.state.cotData[5].data[0].upper}
                  lower={this.state.cotData[5].data[0].lower}
                  upperColor={"#42f54b"}
                  lowerColor={"#b0413e"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>COT1-Index 5 / 13 / 26 / 54 / 156 W-OSZ </h4>
              </MDBCardHeader>
              <MDBCardBody>
                <Cot1OSZ_Maxim
                  userData={OSZ_5_13_26_52_156_data}
                  name={this.props.future + "-ABS-NonComS"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>COT2-Index 5 / 13 / 26 / 54 / 104 / 208 W-OSZ </h4>
              </MDBCardHeader>
              <MDBCardBody>
                <Cot2OszAdvanced
                  userData={OSZ_COT2_5_13_26_52_104_208_data}
                  name={this.props.future + "-ABS-NonComS"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>SWAP-Dealer Short</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <CotABS
                  userData={[this.state.cotData[19]]}
                  name={this.props.future + "-ABS-Cot1"}
                  upper={this.state.cotData[21].data[0].upper}
                  lower={this.state.cotData[21].data[0].lower}
                  upperColor={"#b0413e"}
                  lowerColor={"#42f54b"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>SWAP-Dealer Netto</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <CotABS
                  userData={[this.state.cotData[20]]}
                  name={this.props.future + "-ABS-Cot1"}
                  upper={this.state.cotData[22].data[0].upper}
                  lower={this.state.cotData[22].data[0].lower}
                  upperColor={"#42f54b"}
                  lowerColor={"#b0413e"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6">
            <MDBCard>
              <MDBCardHeader color="blue-grey darken-2">
                <h4>Commercials Netto 26 W-OSZ</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <Cot1OSZ
                  userData={[this.state.cotData[1]]}
                  name={this.props.future + "-OSZ-Cot1"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>Commercials Short 208 W-OSZ</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <Cot2OSZ
                  userData={[this.state.cotData[3]]}
                  name={this.props.future + "-OSZ-Cot2"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow></MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>OI-Absolut</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <CotABS
                  userData={[this.state.cotData[6]]}
                  name={this.props.future + "-ABS-OI"}
                  upper={this.state.cotData[14].data[0].upper}
                  lower={this.state.cotData[14].data[0].lower}
                  upperColor={"#b0413e"}
                  lowerColor={"#b0413e"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>COM-Short / OI %</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <CotABS
                  userData={[this.state.cotData[13]]}
                  name={this.props.future + "-OSZ-COM_S/OI"}
                  upper={this.state.cotData[15].data[0].upper}
                  lower={this.state.cotData[15].data[0].lower}
                  upperColor={"#b0413e"}
                  lowerColor={"#42f54b"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>NonComLong-Absolut</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <CotABS
                  userData={[this.state.cotData[8]]}
                  name={this.props.future + "-ABS-NonComL"}
                  upper={this.state.cotData[10].data[0].upper}
                  lower={this.state.cotData[10].data[0].lower}
                  upperColor={"#b0413e"}
                  lowerColor={"#42f54b"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="mb-4">
              <MDBCardHeader color="blue-grey darken-2">
                <h4>NonComShort-Absolut</h4>
              </MDBCardHeader>
              <MDBCardBody>
                <CotABS
                  userData={[this.state.cotData[9]]}
                  name={this.props.future + "-ABS-NonComS"}
                  upper={this.state.cotData[11].data[0].upper}
                  lower={this.state.cotData[11].data[0].lower}
                  upperColor={"#42f54b"}
                  lowerColor={"#b0413e"}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default CotModule;
