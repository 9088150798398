import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBContainer,
  MDBCard,
  MDBCardBody,
} from "mdbreact";

class NavbarCOT extends Component {
  state = {
    loading: true,
    products: [],
    activeItem: "1",
  };

  toggle = (tab) => () => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  async componentDidMount() {
    let response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/future/list/`
    );
    if (!response.ok) {
      return;
    }

    let products = await response.json();
    this.setState({ loading: false, products: products });
    console.log("loading NAVBAR");
    //console.log(this.props.match.path);
    console.log(this.props);
  }
  //<h2 className="ProductList-title">Future List ({this.state.products.length})</h2>
  render() {
    if (!this.state.loading) {
      console.log("loading NAVBAR - Teil 2");
      //console.log(this.state.products);
      return (
        <div className="FutureList">
          <div className="FutureList-container">
            <MDBCard className="my-4">
              <MDBCardBody className="py-0 px-2">
                <MDBNav pills color="teal" className="nav-justified">
                  {this.state.products.map((product, index) => {
                    //console.log(product.ID );
                    //to={`/cot-tool/${product.Symbol}`}
                    return (
                      <MDBNavItem>
                        <div className="future-tab" key={product.id}>
                          <MDBNavLink
                            to={`/cot-tool/${product.Symbol}`}
                            className={
                              this.state.activeItem === product.ID
                                ? "active"
                                : ""
                            }
                            onClick={this.toggle(product.ID)}
                          >
                            {product.Symbol}
                          </MDBNavLink>
                        </div>
                      </MDBNavItem>
                    );
                  })}
                </MDBNav>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      );
    }
    return <h2 className="FutureList-title">Waiting for API...</h2>;
  }
}

export default NavbarCOT;
